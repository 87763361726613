@media only screen and (max-width: 768px){
  .header{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .log{
    align-items: center;
    display: flex;
    flex-direction: row;
  }
  .logo{
    width: 25px;
    height: 25px;
    border-radius: 50%;
  }
  .Header-Discription{
    font-size: 0.8rem;
    color: white;
  }
  .secondd{
    border-radius: 10px;
    position: fixed;
    right: 2rem;    
    z-index: 99;
    width: 105px;
    text-align: center;
  }
  ul{
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 110px;
    background-color: #4c6566;
    list-style: none;
    
  }
  .li{
    cursor: pointer;
    margin-left: -41px;
   list-style: none; 
  }
  .li{
    text-decoration: none;
    color: #185147;

  }
  .li a:hover{
    color: #4becce;
  }
}
@media only screen and (max-width: 700px){
  .header{
    display: flex;
    flex-direction: row;
  }
  .secondd{
    margin-top: 5px;
    display: flex;
    flex-direction: row;
    gap: 1px;
    margin-left: -30px;
  }
  ul{
    display: flex;
  }
  .li{
    list-style: none;
    font-size:13px;
    cursor: pointer;
  }
  .li a{
    text-decoration:  none;
    color: #bbfff2;
  }
  .li a:hover{
    color: #50efd2
  }
  .log{
    margin: 10px 0px 10px 10px;
    display: flex;
    flex-direction: row;
  }
  .logo{
    width: 25px;
    height: 25px;
    border-radius: 50%;
  }
  .Header-Discription{
    font-size: 11px;
  }
}
@media only screen and (max-width: 1000px){
  .header{
    display: flex;
    flex-direction: row;
  }
  .secondd{
    margin-top: 5px;
    display: flex;
    flex-direction: row;
    gap: 1px;
    margin-left: -30px;
  }
  ul{
    display: flex;
  }
  .li{
    list-style: none;
    font-size:15px;
    cursor: pointer;
  }
  .li a{
    text-decoration:  none;
    color:   #bbfff2;
  }
  .li a:hover{
    color:  #38fbd7;
  }
  .log{
    margin: 10px 0px 10px 10px;
    display: flex;
    flex-direction: row;
  }
  .logo{
    width: 35px;
    height: 35px;
    border-radius: 50%;
  }
  .Header-Discription{
    margin-left: 5px;
    font-size: 15px;
  }
}
@media only screen and (max-width: 1300px) {
  .header{
    display: flex;
    flex-direction: row;
  }
  .secondd{
    margin-top: 5px;
    display: flex;
    flex-direction: row;
    gap: 1px;
    margin-left: -30px;
  }
  ul{
    display: flex;
  }
  .li{
    list-style: none;
    font-size:17px;
    cursor: pointer;
  }
  .li a{
    text-decoration:  none;
    color:   #bbfff2;
  }
  .li a:hover{
    color:  #4cf2d4;
  }
  .log{
    margin: 10px 0px 10px 10px;
    display: flex;
    flex-direction: row;
  }
  .logo{
    width: 45px;
    height: 45px;
    border-radius: 50%;
  }
  .Header-Discription{
    margin-left: 5px;
    font-size: 18px;
  }
}