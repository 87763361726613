.Test{
    display: flex;
    padding: 1rem;
    background-color: #104547;
    margin-top: -17vh;
    padding-top: 10vh;
    padding-left: 2rem;
}
.monials{
    display: flex;
}
.lefft{
    width: 60vw;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}
.theory{
    color: white;
    font-size: larger;
    font-weight: 500;
    word-spacing: 10px;
}
.br{
    margin-bottom: 1rem;
}
.Trainers-name{
    color: #2ef5ff;
}
.status{
    color: white;
}
.immmg{
    position: relative;
    width: 230px;
    height: 300px;
    background-color: #2ef5ff;
    z-index: 5;
}
.butn{
    display: flex;
    margin-top: 1rem;
}
.buttons{
    width: 50px;
    height: 40px;
    background-image: url("/src/assets/leftArrow.png");
    background-size: 30px;
    background-color: #104547;
    background-repeat: no-repeat;
    border: 2px solid #104547;
    cursor: pointer;
}
.buttonss{
    border: 2px solid #104547;
    cursor: pointer;
    width: 50px;
    height: 40px;
    background-image: url("/src/assets/rightArrow.png");
    background-size: 30px;
    background-color: #104547;
    background-repeat: no-repeat;
}
.rightt{
    margin-top: 3rem;
    margin-left: 3rem;
}
.spn{
    display: flex;
    gap: 1rem;
}
.imagess{
    position: relative;
}
.back1{
    position: relative;
    width: 230px;
    height: 300px;
    bottom: -1.5rem;
    right: 15rem;
    background-color: #2ef5ff;
    z-index: 3rem;
    animation: backk 5s  linear infinite;
}
@keyframes backk {
    0% {
        right: 40;
      }
      
        50% {
        right: 20%;    
      }
      100%{
          right: 40;
      }
}
.back2{
    position: relative;
    width: 230px;
    height: 300px;
    bottom: 1.5rem;
    right: 35rem;
    border: 5px solid #ddeded;
    border-color: #2ef5ff;
    z-index: 3rem;
    animation: backkk 5s  linear infinite;
}
@keyframes backkk {
    0% {
      right: 10;
    }
    
    50% {
      right: 50%;    
    }
    100%{
        right: 10;
    }
}
.mon{
    color: white;
}
@media only screen and (min-width: 300px) {
    .Test{
        display: flex;
        padding: 1rem;
        background-color: #104547;
        margin-top: -17vh;
        padding-top: 10vh;
        padding-left: 2rem;
    }
    .monials{
        display: flex;
        flex-direction: column;
    }
    .lefft{
        padding-left: 5vw;
        width: 50vw;
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
    }
    .theory{
        color: white;
        font-size: 12px;
        font-weight: 500;
        padding-top: 70px;
        word-spacing: 10px;
    }
    .br{
        margin-bottom: 1rem;
    }
    .Trainers-name{
        color: #2ef5ff;
    }
    .status{
        color: white;
    }
    .immmg{
        position: relative;
        width: 180px;
        height: 230px;
        background-color: #2ef5ff;
        z-index: 5;
    }
    .butn{
        display: flex;
        margin-top: 1rem;
    }
    .buttons{
        width: 50px;
        height: 40px;
        background-image: url("/src/assets/leftArrow.png");
        background-size: 30px;
        background-color: #104547;
        background-repeat: no-repeat;
        border: 2px solid #104547;
        cursor: pointer;
    }
    .buttonss{
        border: 2px solid #104547;
        cursor: pointer;
        width: 50px;
        height: 40px;
        background-image: url("/src/assets/rightArrow.png");
        background-size: 30px;
        background-color: #104547;
        background-repeat: no-repeat;
    }
    .rightt{
        margin-top: 3rem;
        margin-left: 3rem;
    }
    .spn{
        display: flex;
        gap: 1rem;
        width: 70vw;
    }
    .imagess{
        position: relative;
    }
    .back1{
        position: relative;
        width: 180px;
        height: 230px;
        bottom: -1.5rem;
        right: 15rem;
        background-color: #2ef5ff;
        z-index: 3rem;
        animation: backk 5s  linear infinite;
    }
    @keyframes backk {
        0% {
            right: 10;
          }
          
            50% {
            right: 20%;    
          }
          100%{
              right: 10;
          }
    }
    .back2{
        position: relative;
        width: 180px;
        height: 230px;
        bottom: 1.5rem;
        right: 35rem;
        border: 5px solid #ddeded;
        border-color: #2ef5ff;
        z-index: 3rem;
        animation: backkk 5s  linear infinite;
    }
    @keyframes backkk {
        0% {
            right: 60%;
          }
          
            50% {
            right: 77%;    
          }
          100%{
              right: 60%;
          }
    }
    .mon{
        color: white;
    }
    .butt1{
        width: 28px;
    }
    .butt2{
        width: 30px;
    }
}
@media only screen and (min-width: 400px){
    .Test{
        display: flex;
        padding: 1rem;
        background-color: #104547;
        margin-top: -17vh;
        padding-top: 10vh;
        padding-left: 2rem;
    }
    .monials{
        display: flex;
        flex-direction: column;
    }
    .lefft{
        padding-left: 10vw;
        width: 50vw;
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
    }
    .theory{
        color: white;
        font-size: 15px;
        font-weight: 500;
        padding-top: 50px;
        word-spacing: 10px;
    }
    .br{
        margin-bottom: 1rem;
    }
    .Trainers-name{
        color: #2ef5ff;
    }
    .status{
        color: white;
    }
    .immmg{
        position: relative;
        width: 180px;
        height: 230px;
        background-color: #2ef5ff;
        z-index: 5;
    }
    .butn{
        display: flex;
        margin-top: 1rem;
    }
    .buttons{
        width: 50px;
        height: 40px;
        background-image: url("/src/assets/leftArrow.png");
        background-size: 30px;
        background-color: #104547;
        background-repeat: no-repeat;
        border: 2px solid #104547;
        cursor: pointer;
    }
    .buttonss{
        border: 2px solid #104547;
        cursor: pointer;
        width: 50px;
        height: 40px;
        background-image: url("/src/assets/rightArrow.png");
        background-size: 30px;
        background-color: #104547;
        background-repeat: no-repeat;
    }
    .rightt{
        margin-top: 3rem;
        margin-left: 3rem;
    }
    .spn{
        display: flex;
        gap: 1rem;
    }
    .imagess{
        position: relative;
    }
    .back1{
        position: relative;
        width: 180px;
        height: 230px;
        bottom: -1.5rem;
        right: 15rem;
        background-color: #2ef5ff;
        z-index: 3rem;
        animation: backk 5s  linear infinite;
    }
    @keyframes backk {
        0% {
            right: 10;
          }
          
            50% {
            right: 20%;    
          }
          100%{
              right: 10;
          }
    }
    .back2{
        position: relative;
        width: 180px;
        height: 230px;
        bottom: 1.5rem;
        right: 35rem;
        border: 5px solid #ddeded;
        border-color: #2ef5ff;
        z-index: 3rem;
        animation: backkk 5s  linear infinite;
    }
    @keyframes backkk {
        0% {
            right: 60%;
          }
          
            50% {
            right: 77%;    
          }
          100%{
              right: 60%;
          }
    }
    .mon{
        color: white;
    }
    .butt1{
        width: 28px;
    }
    .butt2{
        width: 30px;
    }
}
@media only screen and (min-width: 600px){
    .Test{
        display: flex;
        padding: 1rem;
        background-color: #104547;
        margin-top: -17vh;
        padding-top: 10vh;
        padding-left: 2rem;
    }
    .monials{
        display: flex;
        flex-direction: row;
    }
    .lefft{
        width: 50vw;
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        margin-left: -10vw;
    }
    .theory{
        color: white;
        font-size: 15px;
        font-weight: 500;
        word-spacing: 10px;
    }
    .br{
        margin-bottom: 1rem;
    }
    .Trainers-name{
        color: #2ef5ff;
    }
    .status{
        color: white;
    }
    .immmg{
        position: relative;
        width: 180px;
        height: 230px;
        background-color: #2ef5ff;
        z-index: 5;
    }
    .butn{
        display: flex;
        margin-top: 1rem;
    }
    .buttons{
        width: 50px;
        height: 40px;
        background-image: url("/src/assets/leftArrow.png");
        background-size: 30px;
        background-color: #104547;
        background-repeat: no-repeat;
        border: 2px solid #104547;
        cursor: pointer;
    }
    .buttonss{
        border: 2px solid #104547;
        cursor: pointer;
        width: 50px;
        height: 40px;
        background-image: url("/src/assets/rightArrow.png");
        background-size: 30px;
        background-color: #104547;
        background-repeat: no-repeat;
    }
    .rightt{
        margin-top: 3rem;
        margin-left: 3rem;
    }
    .spn{
        display: flex;
        gap: 1rem;
    }
    .imagess{
        position: relative;
    }
    .back1{
        position: relative;
        width: 180px;
        height: 230px;
        bottom: -1.5rem;
        right: 15rem;
        background-color: #2ef5ff;
        z-index: 3rem;
        animation: backk 5s  linear infinite;
    }
    @keyframes backk {
        0% {
            right: 10;
          }
          
            50% {
            right: 20%;    
          }
          100%{
              right: 10;
          }
    }
    .back2{
        position: relative;
        width: 180px;
        height: 230px;
        bottom: 1.5rem;
        right: 35rem;
        border: 5px solid #ddeded;
        border-color: #2ef5ff;
        z-index: 3rem;
        animation: backkk 5s  linear infinite;
    }
    @keyframes backkk {
        0% {
            right: 60%;
          }
          
            50% {
            right: 77%;    
          }
          100%{
              right: 60%;
          }
    }
    .mon{
        color: white;
    }
    .butt1{
        width: 28px;
    }
    .butt2{
        width: 30px;
    }
}

@media only screen and (min-width: 768px){
    .Test{
        display: flex;
        padding: 1rem;
        background-color: #104547;
        margin-top: -17vh;
        padding-top: 10vh;
        padding-left: 2rem;
    }
    .monials{
        display: flex;
        flex-direction: row;
    }
    .lefft{
        width: 50vw;
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
    }
    .theory{
        color: white;
        font-size: 15px;
        font-weight: 500;
        word-spacing: 10px;
    }
    .br{
        margin-bottom: 1rem;
    }
    .Trainers-name{
        color: #2ef5ff;
    }
    .status{
        color: white;
    }
    .immmg{
        position: relative;
        width: 180px;
        height: 230px;
        background-color: #2ef5ff;
        z-index: 5;
    }
    .butn{
        display: flex;
        margin-top: 1rem;
    }
    .buttons{
        width: 50px;
        height: 40px;
        background-image: url("/src/assets/leftArrow.png");
        background-size: 30px;
        background-color: #104547;
        background-repeat: no-repeat;
        border: 2px solid #104547;
        cursor: pointer;
    }
    .buttonss{
        border: 2px solid #104547;
        cursor: pointer;
        width: 50px;
        height: 40px;
        background-image: url("/src/assets/rightArrow.png");
        background-size: 30px;
        background-color: #104547;
        background-repeat: no-repeat;
    }
    .rightt{
        margin-top: 3rem;
        margin-left: 3rem;
    }
    .spn{
        display: flex;
        gap: 1rem;
    }
    .imagess{
        position: relative;
    }
    .back1{
        position: relative;
        width: 180px;
        height: 230px;
        bottom: -1.5rem;
        right: 15rem;
        background-color: #2ef5ff;
        z-index: 3rem;
        animation: backk 5s  linear infinite;
    }
    @keyframes backk {
        0% {
            right: 10;
          }
          
            50% {
            right: 20%;    
          }
          100%{
              right: 10;
          }
    }
    .back2{
        position: relative;
        width: 180px;
        height: 230px;
        bottom: 1.5rem;
        right: 35rem;
        border: 5px solid #ddeded;
        border-color: #2ef5ff;
        z-index: 3rem;
        animation: backkk 5s  linear infinite;
    }
    @keyframes backkk {
        0% {
            right: 60%;
          }
          
            50% {
            right: 77%;    
          }
          100%{
              right: 60%;
          }
    }
    .mon{
        color: white;
    }
    .butt1{
        width: 28px;
    }
    .butt2{
        width: 30px;
    }
}
@media only screen and (min-width: 900px){
    .Test{
        display: flex;
        padding: 1rem;
        background-color: #104547;
        margin-top: -17vh;
        padding-top: 10vh;
        padding-left: 2rem;
    }
    .monials{
        display: flex;
    }
    .lefft{
        width: 50vw;
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
    }
    .theory{
        color: white;
        font-size: 15px;
        font-weight: 500;
        word-spacing: 10px;
    }
    .br{
        margin-bottom: 1rem;
    }
    .Trainers-name{
        color: #2ef5ff;
    }
    .status{
        color: white;
    }
    .immmg{
        position: relative;
        width: 180px;
        height: 230px;
        background-color: #2ef5ff;
        z-index: 5;
    }
    .butn{
        display: flex;
        margin-top: 1rem;
    }
    .buttons{
        width: 50px;
        height: 40px;
        background-image: url("/src/assets/leftArrow.png");
        background-size: 30px;
        background-color: #104547;
        background-repeat: no-repeat;
        border: 2px solid #104547;
        cursor: pointer;
    }
    .buttonss{
        border: 2px solid #104547;
        cursor: pointer;
        width: 50px;
        height: 40px;
        background-image: url("/src/assets/rightArrow.png");
        background-size: 30px;
        background-color: #104547;
        background-repeat: no-repeat;
    }
    .rightt{
        margin-top: 3rem;
        margin-left: 3rem;
    }
    .spn{
        display: flex;
        gap: 1rem;
    }
    .imagess{
        position: relative;
    }
    .back1{
        position: relative;
        width: 180px;
        height: 230px;
        bottom: -1.5rem;
        right: 15rem;
        background-color: #2ef5ff;
        z-index: 3rem;
        animation: backk 5s  linear infinite;
    }
    @keyframes backk {
        0% {
            right: 10;
          }
          
            50% {
            right: 20%;    
          }
          100%{
              right: 10;
          }
    }
    .back2{
        position: relative;
        width: 180px;
        height: 230px;
        bottom: 1.5rem;
        right: 35rem;
        border: 5px solid #ddeded;
        border-color: #2ef5ff;
        z-index: 3rem;
        animation: backkk 5s  linear infinite;
    }
    @keyframes backkk {
        0% {
            right: 60%;
          }
          
            50% {
            right: 77%;    
          }
          100%{
              right: 60%;
          }
    }
    .mon{
        color: white;
    }
    .butt1{
        width: 28px;
    }
    .butt2{
        width: 30px;
    }
}
@media only screen and (min-width: 930px){
    .Test{
        display: flex;
        padding: 1rem;
        background-color: #104547;
        margin-top: -17vh;
        padding-top: 10vh;
        padding-left: 2rem;
    }
    .monials{
        display: flex;
    }
    .lefft{
        width: 60vw;
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
    }
    .theory{
        color: white;
        font-size: larger;
        font-weight: 500;
        word-spacing: 10px;
    }
    .br{
        margin-bottom: 1rem;
    }
    .Trainers-name{
        color: #2ef5ff;
    }
    .status{
        color: white;
    }
    .immmg{
        position: relative;
        width: 230px;
        height: 300px;
        background-color: #2ef5ff;
        z-index: 5;
    }
    .butn{
        display: flex;
        margin-top: 1rem;
    }
    .buttons{
        width: 50px;
        height: 40px;
        background-image: url("/src/assets/leftArrow.png");
        background-size: 30px;
        background-color: #104547;
        background-repeat: no-repeat;
        border: 2px solid #104547;
        cursor: pointer;
    }
    .buttonss{
        border: 2px solid #104547;
        cursor: pointer;
        width: 50px;
        height: 40px;
        background-image: url("/src/assets/rightArrow.png");
        background-size: 30px;
        background-color: #104547;
        background-repeat: no-repeat;
    }
    .rightt{
        margin-top: 3rem;
        margin-left: 3rem;
    }
    .spn{
        display: flex;
        gap: 1rem;
    }
    .imagess{
        position: relative;
    }
    .back1{
        position: relative;
        width: 230px;
        height: 300px;
        bottom: -1.5rem;
        right: 15rem;
        background-color: #2ef5ff;
        z-index: 3rem;
        animation: backk 5s  linear infinite;
    }
    @keyframes backk {
        0% {
            right: 40;
          }
          
            50% {
            right: 20%;    
          }
          100%{
              right: 40;
          }
    }
    .back2{
        position: relative;
        width: 230px;
        height: 300px;
        bottom: 1.5rem;
        right: 35rem;
        border: 5px solid #ddeded;
        border-color: #2ef5ff;
        z-index: 3rem;
        animation: backkk 5s  linear infinite;
    }
    @keyframes backkk {
        0% {
          right: 10;
        }
        
        50% {
          right: 50%;    
        }
        100%{
            right: 10;
        }
    }
    .mon{
        color: white;
    }
    .butt1{
        width: 28px;
    }
    .butt2{
        width: 30px;
    }
}
@media only screen and (min-width: 1150px){
    .Test{
        display: flex;
        padding: 1rem;
        background-color: #104547;
        margin-top: -17vh;
        padding-top: 10vh;
        padding-left: 2rem;
    }
    .monials{
        display: flex;
    }
    .lefft{
        width: 60vw;
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
    }
    .theory{
        color: white;
        font-size: larger;
        font-weight: 500;
        word-spacing: 10px;
    }
    .br{
        margin-bottom: 1rem;
    }
    .Trainers-name{
        color: #2ef5ff;
    }
    .status{
        color: white;
    }
    .immmg{
        position: relative;
        width: 250px;
        height: 250px;
        background-color: #2ef5ff;
        z-index: 5;
    }
    .butn{
        display: flex;
        margin-top: 1rem;
    }
    
    .rightt{
        margin-top: 3rem;
        margin-left: 3rem;
    }
    .spn{
        display: flex;
        gap: 1rem;
    }
    .imagess{
        position: relative;
    }
    .back1{
        position: relative;
        width: 250px;
        height: 250px;
        bottom: -1.5rem;
        right: 15rem;
        background-color: #2ef5ff;
        z-index: 3rem;
        animation: backk 5s  linear infinite;
    }
    @keyframes backk {
        0% {
            right: 40;
          }
          
            50% {
            right: 20%;    
          }
          100%{
              right: 40;
          }
    }
    .back2{
        position: relative;
        width: 250px;
        height: 250px;
        bottom: 1.5rem;
        right: 35rem;
        border: 5px solid #ddeded;
        border-color: #2ef5ff;
        z-index: 3rem;
        animation: backkk 5s  linear infinite;
    }
    @keyframes backkk {
        0% {
          right: 10;
        }
        
        50% {
          right: 50%;    
        }
        100%{
            right: 10;
        }
    }
    .mon{
        color: white;
    }   
    .butt1{
        width: 28px;
    }
    .butt2{
        width: 30px;
    }
}