.images1{
    height: 500px;
    width: 250px;
    border-radius: 10px;
    border: 5px solid #104547;
}
.whyUs{
    background-color: #104547;
    margin-top: -17vh;
    padding-top: 10vh;
    width: 100vw;
    justify-content: center;
    padding-bottom: 10vh;
    display: flex;
    gap: 2rem;
}
.images{
    display: flex;
    gap: 1rem   ;
}
.images2{
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.upeer-img{
    width: 420px;
    height: 250px;
    border-radius: 10px;
}
.images3{
    display: flex;
    gap: 1rem   ;
}
.Left-img{
    width: 200px;
    height: 240px;
    border-radius: 10px;
}
.right-img{
    width: 200px;
    height: 240px;
    border-radius: 10px;
}
.dicrip{
    font-size: larger;
    font-weight: 600;
    color: white;
}
li{
    list-style: georgian;
    color: white;
    margin-left: 1rem;
}
.cont{
    display: flex;
    flex-direction: column;
}
.name{
    color: #aee5e7;
    font-size: large;
    cursor: pointer;
}
.name:hover{
    color: #6af1f6;
}
.brand-img{
    width: 50px;
    margin-top: 10px;
}
.imagess{
    display: flex;
    gap: 1rem;
}
.reasons-heading{
    color: #aee5e7;
}
.reasons-heading:hover{
    color: #6af1f6;
    cursor: pointer;
}
@media only screen and (min-width: 300px){
    .images1{
        height: 150px;
        width: 90px;
        border-radius: 10px;
        border: 5px solid #104547;
    }
    .whyUs{
        background-color: #104547;
        margin-top: -17vh;
        padding-top: 10vh;
        padding-bottom: 10vh;
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }
    .images{
        display: flex;
        width: 100vw;
        justify-content: center;
        gap: 0.3rem;
    }
    .images2{
        display: flex;
        flex-direction: column;
        gap: 0.3rem;
    }
    .upeer-img{
        width: 150px;
        height: 75px;
        border-radius: 10px;
    }
    .images3{
        display: flex;
        gap: 0.3rem;
    }
    .Left-img{
        width: 72px;
        height: 75px;
        border-radius: 10px;
    }
    .right-img{
        width: 72px;
        height: 75px;
        border-radius: 10px;
    }
    .dicrip{
        font-size: 13px;
        font-weight: 600;
        color: white;
    }
    li{
        list-style: georgian;
        color: white;
        margin-left: 0.7rem;
    }
    .cont{
        display: flex;
        flex-direction: column;
        margin-top: -4vh;
        margin-left: 15vw;
    }
    .name{
        color: #aee5e7;
        font-size: 15px;
        cursor: pointer;
    }
    .name:hover{
        color: #6af1f6;
    }
    .brand-img{
        width: 30px;
        margin-top: 10px;
    }
    .imagess{
        display: flex;
        gap: 0.5rem;
    }
    .reasons-heading{
        color: #aee5e7;
    }
    .reasons-heading:hover{
        color: #6af1f6;
        cursor: pointer;
    }
    
}
@media only screen and (min-width: 400px){
    .images1{
        height: 200px;
        width: 100px;
        border-radius: 10px;
        border: 5px solid #104547;
    }
    .whyUs{
        background-color: #104547;
        margin-top: -17vh;
        padding-top: 10vh;
        padding-bottom: 10vh;
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }
    .images{
        display: flex;
        width: 100vw;
        justify-content: center;
        gap: 0.3rem;
    }
    .images2{
        display: flex;
        flex-direction: column;
        gap: 0.3rem;
    }
    .upeer-img{
        width: 200px;
        height: 100px;
        border-radius: 10px;
    }
    .images3{
        display: flex;
        gap: 0.3rem;
    }
    .Left-img{
        width: 96px;
        height: 100px;
        border-radius: 10px;
    }
    .right-img{
        width: 96px;
        height: 100px;
        border-radius: 10px;
    }
    .dicrip{
        font-size: 13px;
        font-weight: 600;
        color: white;
    }
    li{
        list-style: georgian;
        color: white;
        margin-left: 0.7rem;
    }
    .cont{
        display: flex;
        flex-direction: column;
        margin-top: -4vh;
        margin-left: 15vw;
    }
    .name{
        color: #aee5e7;
        font-size: 15px;
        cursor: pointer;
    }
    .name:hover{
        color: #6af1f6;
    }
    .brand-img{
        width: 30px;
        margin-top: 10px;
    }
    .imagess{
        display: flex;
        gap: 0.5rem;
    }
    .reasons-heading{
        color: #aee5e7;
    }
    .reasons-heading:hover{
        color: #6af1f6;
        cursor: pointer;
    }
}
@media only screen and (min-width: 600px){
    .images1{
        height: 250px;
        width: 120px;
        border-radius: 10px;
        border: 5px solid #104547;
    }
    .whyUs{
        background-color: #104547;
        margin-top: -17vh;
        padding-top: 10vh;
        padding-bottom: 10vh;
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }
    .images{
        display: flex;
        width: 100vw;
        justify-content: center;
        gap: 0.3rem;
    }
    .images2{
        display: flex;
        flex-direction: column;
        gap: 0.3rem;
    }
    .upeer-img{
        width: 235px;
        height: 120px;
        border-radius: 10px;
    }
    .images3{
        display: flex;
        gap: 0.3rem;
    }
    .Left-img{
        width: 117px;
        height: 125px;
        border-radius: 10px;
    }
    .right-img{
        width: 117px;
        height: 125px;
        border-radius: 10px;
    }
    .dicrip{
        font-size: 15px;
        font-weight: 600;
        color: white;
    }
    li{
        list-style: georgian;
        color: white;
        margin-left: 0.7rem;
    }
    .cont{
        display: flex;
        flex-direction: column;
        margin-top: -4vh;
        margin-left: 30vw;
    }
    .name{
        color: #aee5e7;
        font-size: 15px;
        cursor: pointer;
    }
    .name:hover{
        color: #6af1f6;
    }
    .brand-img{
        width: 30px;
        margin-top: 10px;
    }
    .imagess{
        display: flex;
        gap: 0.5rem;
    }
    .reasons-heading{
        color: #aee5e7;
    }
    .reasons-heading:hover{
        color: #6af1f6;
        cursor: pointer;
    }
}

@media only screen and (min-width: 768px){
    .images1{
        height: 250px;
        width: 120px;
        border-radius: 10px;
        border: 5px solid #104547;
    }
    .whyUs{
        background-color: #104547;
        margin-top: -17vh;
        padding-top: 10vh;
        padding-bottom: 10vh;
        display: flex;
        gap: 1rem;
    }
    .images{
        display: flex;
        gap: 0.3rem;
    }
    .images2{
        display: flex;
        flex-direction: column;
        gap: 0.3rem;
    }
    .upeer-img{
        width: 235px;
        height: 120px;
        border-radius: 10px;
    }
    .images3{
        display: flex;
        gap: 0.3rem;
    }
    .Left-img{
        width: 117px;
        height: 125px;
        border-radius: 10px;
    }
    .right-img{
        width: 117px;
        height: 125px;
        border-radius: 10px;
    }
    .dicrip{
        font-size: 15px;
        font-weight: 600;
        color: white;
    }
    li{
        list-style: georgian;
        color: white;
        margin-left: 0.7rem;
    }
    .cont{
        display: flex;
        flex-direction: column;
        margin-top: -4vh;
    }
    .name{
        color: #aee5e7;
        font-size: 15px;
        cursor: pointer;
    }
    .name:hover{
        color: #6af1f6;
    }
    .brand-img{
        width: 30px;
        margin-top: 10px;
    }
    .imagess{
        display: flex;
        gap: 0.5rem;
    }
    .reasons-heading{
        color: #aee5e7;
    }
    .reasons-heading:hover{
        color: #6af1f6;
        cursor: pointer;
    }
}
@media only screen and (min-width: 900px){
    
    .images1{
        height: 300px;
        width: 150px;
        border-radius: 10px;
        border: 5px solid #104547;
    }
    .whyUs{
        background-color: #104547;
        margin-top: -17vh;
        padding-top: 10vh;
        padding-left: 2rem;
        padding-bottom: 10vh;
        display: flex;
        gap: 1rem;
    }
    .images{
        display: flex;
        gap: 0.3rem;
    }
    .images2{
        display: flex;
        flex-direction: column;
        gap: 0.3rem;
    }
    .upeer-img{
        width: 285px;
        height: 150px;
        border-radius: 10px;
    }
    .images3{
        display: flex;
        gap: 0.3rem;
    }
    .Left-img{
        width: 140px;
        height: 150px;
        border-radius: 10px;
    }
    .right-img{
        width: 140px;
        height: 150px;
        border-radius: 10px;
    }
    .dicrip{
        font-size: 15px;
        font-weight: 600;
        color: white;
    }
    li{
        list-style: georgian;
        color: white;
        margin-left: 1rem;
    }
    .cont{
        display: flex;
        flex-direction: column;
        margin-top: -4vh;
    }
    .name{
        color: #aee5e7;
        font-size: 15px;
        cursor: pointer;
    }
    .name:hover{
        color: #6af1f6;
    }
    .brand-img{
        width: 30px;
        margin-top: 10px;
    }
    .imagess{
        display: flex;
        gap: 0.5rem;
    }
    .reasons-heading{
        color: #aee5e7;
    }
    .reasons-heading:hover{
        color: #6af1f6;
        cursor: pointer;
    }
}
@media only screen and (min-width: 1150px){
    .images1{
        height: 500px;
        width: 250px;
        border-radius: 10px;
        border: 5px solid #104547;
    }
    .whyUs{
        background-color: #104547;
        margin-top: -17vh;
        padding-top: 10vh;
        padding-left: 2rem;
        padding-bottom: 10vh;
        display: flex;
        gap: 2rem;
    }
    .images{
        display: flex;
        gap: 1rem   ;
    }
    .images2{
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }
    .upeer-img{
        width: 420px;
        height: 250px;
        border-radius: 10px;
    }
    .images3{
        display: flex;
        gap: 1rem   ;
    }
    .Left-img{
        width: 200px;
        height: 240px;
        border-radius: 10px;
    }
    .right-img{
        width: 200px;
        height: 240px;
        border-radius: 10px;
    }
    .dicrip{
        font-size: larger;
        font-weight: 600;
        color: white;
    }
    li{
        list-style: georgian;
        color: white;
        margin-left: 1rem;
    }
    .cont{
        display: flex;
        flex-direction: column;
    }
    .name{
        color: #aee5e7;
        font-size: large;
        cursor: pointer;
    }
    .name:hover{
        color: #6af1f6;
    }
    .brand-img{
        width: 50px;
        margin-top: 10px;
    }
    .imagess{
        display: flex;
        gap: 1rem;
    }
    .reasons-heading{
        color: #aee5e7;
    }
    .reasons-heading:hover{
        color: #6af1f6;
        cursor: pointer;
    }
}