.Journey{
    display: flex;
    gap: 12rem;
    width: 100vw;
    justify-content: center;
    margin-top: -17vh;
    padding-top: 10vh;
    padding-left: 1rem;
    padding-bottom: 10vh;
    background-color: #104547;
}
.stroke--text{
    font-size: 3rem;
    font-weight: bold;
    color: white;
    height: 70px;
    padding-bottom: 5px;
  }
  @media only screen and (min-width: 200px){
    .Journey{
        display: flex;
        flex-direction: column;
        width: 100vw;
        text-align: center;
        gap: 4rem;
        margin-top: -17vh;
        padding-top: 10vh;
        padding-bottom: 10vh;
        background-color: #104547;
        margin-left: -1rem;
    }
    .stroke--text{
        font-size: 3rem;
        font-weight: bold;
        color: white;
        height: 70px;
        padding-bottom: 5px;
      }
  }