.footer{
    width: 100vw;
    margin-top: -17vh;
    padding-top: 10vh;
    padding-left: 2rem;
    padding-bottom: 40vh;
    background-color: #104547;
}


.lane{
    height: 3px;
    width: 100vw;
    background-color: #549F93;
    margin-top: 5vh;
    margin-bottom: 5vh;
}


.icons{
    margin-top: 20vh;
    display: flex;
    width: 100vw;
    justify-content: center;
    gap: 5rem;
}
.icon-logo{
    width: 40px;
    cursor: pointer;
}
.footerlog{
    width: 100vw;
    display: flex;
    justify-content: center;
    padding-top: 10vh;
}
.footer-logo{
    width: 70px;
    height: 70px;
    border-radius: 50%;
    cursor: pointer;
}
.footer-logo-dic{
    font-size: x-large;
    padding-left: 10px;
    font-weight: 500;
    color: white;
}
.foot-dis{
    color: #549F93;
    font-size: larger;
    cursor: pointer;
}
.foot-dis:hover{
    color: #d2ece8;
}
.foot{
    display: flex;
    margin-top: 5vh;
    width: 100vw;
    justify-content: center;
}
@media only screen and (min-width: 300px){
    .footer{
        width: 100vw;
        margin-top: -17vh;
        padding-top: 10vh;
        padding-bottom: 40vh;
        background-color: #104547;
    }
    
    
    .lane{
        height: 3px;
        width: 81vw;
        background-color: #549F93;
        margin-top: 5vh;
        margin-bottom: 5vh;
    }
    
    
    .icons{
        margin-left: -2rem;
        margin-top: 20vh;
        display: flex;
        justify-content: center;
        gap: 5rem;
    }
    .icon-logo{
        width: 20px;
        cursor: pointer;
    }
    .footerlog{
        width: 100vw;
        display: flex;
        justify-content: center;
        padding-top: 10vh;
        margin-left: -2rem;
    }
    .footer-logo{
        width: 34px;
        height: 34px;
        border-radius: 50%;
        cursor: pointer;
    }
    .footer-logo-dic{
        font-size: 14px;
        font-weight: 500;
        color: white;
    }
    .foot-dis{
        color: #549F93;
        font-size: 15px;
        cursor: pointer;
        margin-left: -1rem;
    }
    .foot-dis:hover{
        color: #d2ece8;
    }
    .foot{
        display: flex;
        margin-top: 5vh;
        width: 100vw;
        justify-content: center;
        margin-left: -1.5rem;
    }
}
@media only screen and (min-width: 400px){
    .footer{
        width: 100vw;
        margin-top: -17vh;
        padding-top: 10vh;
        padding-bottom: 40vh;
        background-color: #104547;
    }
    
    
    .lane{
        height: 3px;
        width: 84vw;
        background-color: #549F93;
        margin-top: 5vh;
        margin-bottom: 5vh;
    }
    
    
    .icons{
        margin-left: -2rem;
        margin-top: 20vh;
        display: flex;
        justify-content: center;
        gap: 5rem;
    }
    .icon-logo{
        width: 30px;
        cursor: pointer;
    }
    .footerlog{
        width: 100vw;
        display: flex;
        justify-content: center;
        padding-top: 10vh;
        margin-left: -2rem;
    }
    .footer-logo{
        width: 40px;
        height: 40px;
        border-radius: 50%;
        cursor: pointer;
    }
    .footer-logo-dic{
        font-size: large;
        font-weight: 500;
        color: white;
    }
    .foot-dis{
        color: #549F93;
        font-size: large;
        cursor: pointer;
    }
    .foot-dis:hover{
        color: #d2ece8;
    }
    .foot{
        display: flex;
        margin-top: 5vh;
        width: 100vw;
        justify-content: center;
        margin-left: -1.5rem;
    }
}
@media only screen and (min-width: 600px){
        .footer{
        width: 100vw;
        margin-top: -17vh;
        padding-top: 10vh;
        padding-bottom: 40vh;
        background-color: #104547;
    }
    
    
    .lane{
        height: 3px;
        width: 90vw;
        background-color: #549F93;
        margin-top: 5vh;
        margin-bottom: 5vh;
    }
    
    
    .icons{
        margin-top: 20vh;
        display: flex;
        width: 100vw;
        justify-content: center;
        gap: 5rem;
    }
    .icon-logo{
        width: 30px;
        cursor: pointer;
    }
    .footerlog{
        width: 100vw;
        display: flex;
        justify-content: center;
        padding-top: 10vh;
    }
    .footer-logo{
        width: 40px;
        height: 40px;
        border-radius: 50%;
        cursor: pointer;
    }
    .footer-logo-dic{
        font-size: large;
        font-weight: 500;
        color: white;
    }
    .foot-dis{
        color: #549F93;
        font-size: large;
        cursor: pointer;
    }
    .foot-dis:hover{
        color: #d2ece8;
    }
    .foot{
        display: flex;
        margin-top: 5vh;
        width: 100vw;
        justify-content: center;
    }
}

@media only screen and (min-width: 768px){
    .footer{
        width: 100vw;
        margin-top: -17vh;
        padding-top: 10vh;
        padding-bottom: 40vh;
        background-color: #104547;
    }
    
    
    .lane{
        height: 3px;
        width: 90vw;
        background-color: #549F93;
        margin-top: 5vh;
        margin-bottom: 5vh;
    }
    
    
    .icons{
        margin-top: 20vh;
        display: flex;
        width: 100vw;
        justify-content: center;
        gap: 5rem;
    }
    .icon-logo{
        width: 40px;
        cursor: pointer;
    }
    .footerlog{
        width: 100vw;
        display: flex;
        justify-content: center;
        padding-top: 10vh;
    }
    .footer-logo{
        width: 50px;
        height: 50px;
        border-radius: 50%;
        cursor: pointer;
    }
    .footer-logo-dic{
        font-size: large;
        padding-left: 10px;
        font-weight: 500;
        color: white;
    }
    .foot-dis{
        color: #549F93;
        font-size: large;
        cursor: pointer;
    }
    .foot-dis:hover{
        color: #d2ece8;
    }
    .foot{
        display: flex;
        margin-top: 5vh;
        width: 100vw;
        justify-content: center;
    }
}
@media only screen and (min-width: 900px){
    .footer{
        width: 100vw;
        margin-top: -17vh;
        padding-top: 10vh;
        padding-left: 2rem;
        padding-bottom: 40vh;
        background-color: #104547;
    }
    
    
    .lane{
        height: 3px;
        width: 94vw;
        background-color: #549F93;
        margin-top: 5vh;
        margin-bottom: 5vh;
    }
    
    
    .icons{
        margin-top: 20vh;
        display: flex;
        width: 100vw;
        justify-content: center;
        gap: 5rem;
    }
    .icon-logo{
        width: 40px;
        cursor: pointer;
    }
    .footerlog{
        width: 100vw;
        display: flex;
        justify-content: center;
        padding-top: 10vh;
    }
    .footer-logo{
        width: 50px;
        height: 50px;
        border-radius: 50%;
        cursor: pointer;
    }
    .footer-logo-dic{
        font-size: large;
        padding-left: 10px;
        font-weight: 500;
        color: white;
    }
    .foot-dis{
        color: #549F93;
        font-size: large;
        cursor: pointer;
    }
    .foot-dis:hover{
        color: #d2ece8;
    }
    .foot{
        display: flex;
        margin-top: 5vh;
        width: 100vw;
        justify-content: center;
    }
}
@media only screen and (min-width: 1150px){
    .footer{
        width: 100vw;
        margin-top: -17vh;
        padding-top: 10vh;
        padding-left: 2rem;
        padding-bottom: 40vh;
        background-color: #104547;
    }
    
    
    .lane{
        height: 3px;
        width: 94vw;
        background-color: #549F93;
        margin-top: 5vh;
        margin-bottom: 5vh;
    }
    
    
    .icons{
        margin-top: 20vh;
        display: flex;
        width: 100vw;
        justify-content: center;
        gap: 5rem;
    }
    .icon-logo{
        width: 40px;
        cursor: pointer;
    }
    .footerlog{
        width: 100vw;
        display: flex;
        justify-content: center;
        padding-top: 10vh;
    }
    .footer-logo{
        width: 70px;
        height: 70px;
        border-radius: 50%;
        cursor: pointer;
    }
    .footer-logo-dic{
        font-size: x-large;
        padding-left: 10px;
        font-weight: 500;
        color: white;
    }
    .foot-dis{
        color: #549F93;
        font-size: larger;
        cursor: pointer;
    }
    .foot-dis:hover{
        color: #d2ece8;
    }
    .foot{
        display: flex;
        margin-top: 5vh;
        width: 100vw;
        justify-content: center;
    }
    
}