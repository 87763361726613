.plans{
    display: flex;
    gap: 2rem;
    color: white;
    background-color: #104547;
    margin-top: -17vh;
    width: 100vw;
    justify-content: center;
    padding-top: 10vh;
    padding-bottom: 10vh;
}
.left{
    padding-top: 2rem;
    padding-left: 1rem;
    width: 300px;
    height: 420px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    background-color: #4c6566;
    cursor: pointer;
}
.left:hover{
    background-color: #549F93;
    transform: scale(1.1);
}
.plan-discription{
    font-size: 1.3rem;
    font-weight: 600;
}
.price{
    font-size: 3.5rem;
    margin-top: -0px;
}
.lii{
    list-style: none;
    font-size: larger;
    list-style: georgian;
    padding-bottom: 1rem;
}
.p{
    font-size: large;
}
.btn{
    width: 285px;
    height: 40px;
    cursor: pointer;
    background-color: #4c6566;
    border:4px solid #549F93;
    color: white;
}
.btn:hover{
    background-color: #104547;
    border: 4px solid #4c6566;
}
.mid{
    padding-top: 2rem;
    padding-left: 1rem;
    width: 300px;
    height: 420px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    background-color: #4c6566;
    cursor: pointer;
}
.mid:hover{
    background-color: #549F93;
    transform: scale(1.1);
}
.right{
    padding-top: 2rem;
    padding-left: 1rem;
    width: 300px;
    height: 420px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    background-color: #4c6566;
    cursor: pointer;
}
.right:hover{
    background-color: #549F93;
    transform: scale(1.1);
}
@media only screen and (min-width: 200px) {
    .plans{
        display: flex;
        flex-wrap: wrap;
        gap: 0.8rem;
        color: white;
        background-color: #104547;
        margin-top: -17vh;
        width: 100vw;
        justify-content: center;
        padding-top: 10vh;
        padding-bottom: 10vh;
    }
    .left{
        padding-top: 2rem;
        padding-left: 1rem;
        width: 220px;
        height: 310px;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        background-color: #4c6566;
        cursor: pointer;
    }
    .left:hover{
        background-color: #549F93;
        transform: scale(1.1);
    }
    .plan-discription{
        font-size: 0.9rem;
        font-weight: 600;
    }
    .price{
        font-size: 1.5rem;
        margin-top: -0px;
    }
    .lii{
        list-style: none;
        font-size: 15px;
        list-style: georgian;
        padding-bottom: 1rem;
    }
    .p{
        font-size: 15px;
    }
    .btn{
        width: 208px;
        height: 40px;
        cursor: pointer;
        background-color: #4c6566;
        border:4px solid #549F93;
        color: white;
    }
    .btn:hover{
        background-color: #104547;
        border: 4px solid #4c6566;
    }
    .mid{
        padding-top: 2rem;
        padding-left: 1rem;
        width: 220px;
        height: 310px;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        background-color: #4c6566;
        cursor: pointer;
    }
    .mid:hover{
        background-color: #549F93;
        transform: scale(1.1);
    }
    .right{
        padding-top: 2rem;
        padding-left: 1rem;
        width: 220px;
        height: 310px;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        background-color: #4c6566;
        cursor: pointer;
    }
    .right:hover{
        background-color: #549F93;
        transform: scale(1.1);
    }
    
}
@media only screen and (min-width: 768px){
    .plans{
        display: flex;
        gap: 0.8rem;
        color: white;
        background-color: #104547;
        margin-top: -17vh;
        width: 100vw;
        justify-content: center;
        padding-top: 10vh;
        padding-bottom: 10vh;
    }
    .left{
        padding-top: 2rem;
        padding-left: 1rem;
        width: 220px;
        height: 310px;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        background-color: #4c6566;
        cursor: pointer;
    }
    .left:hover{
        background-color: #549F93;
        transform: scale(1.1);
    }
    .plan-discription{
        font-size: 0.9rem;
        font-weight: 600;
    }
    .price{
        font-size: 1.5rem;
        margin-top: -0px;
    }
    .lii{
        list-style: none;
        font-size: 15px;
        list-style: georgian;
        padding-bottom: 1rem;
    }
    .p{
        font-size: 15px;
    }
    .btn{
        width: 208px;
        height: 40px;
        cursor: pointer;
        background-color: #4c6566;
        border:4px solid #549F93;
        color: white;
    }
    .btn:hover{
        background-color: #104547;
        border: 4px solid #4c6566;
    }
    .mid{
        padding-top: 2rem;
        padding-left: 1rem;
        width: 220px;
        height: 310px;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        background-color: #4c6566;
        cursor: pointer;
    }
    .mid:hover{
        background-color: #549F93;
        transform: scale(1.1);
    }
    .right{
        padding-top: 2rem;
        padding-left: 1rem;
        width: 220px;
        height: 310px;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        background-color: #4c6566;
        cursor: pointer;
    }
    .right:hover{
        background-color: #549F93;
        transform: scale(1.1);
    }
}
@media only screen and (min-width: 900px){
    .plans{
        display: flex;
        gap: 1rem;
        color: white;
        background-color: #104547;
        margin-top: -17vh;
        width: 100vw;
        justify-content: center;
        padding-top: 10vh;
        padding-bottom: 10vh;
    }
    .left{
        padding-top: 2rem;
        padding-left: 1rem;
        width: 220px;
        height: 310px;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        background-color: #4c6566;
        cursor: pointer;
    }
    .left:hover{
        background-color: #549F93;
        transform: scale(1.1);
    }
    .plan-discription{
        font-size: 0.9rem;
        font-weight: 600;
    }
    .price{
        font-size: 1.5rem;
        margin-top: -0px;
    }
    .lii{
        list-style: none;
        font-size: 15px;
        list-style: georgian;
        padding-bottom: 1rem;
    }
    .p{
        font-size: 15px;
    }
    .btn{
        width: 208px;
        height: 40px;
        cursor: pointer;
        background-color: #4c6566;
        border:4px solid #549F93;
        color: white;
    }
    .btn:hover{
        background-color: #104547;
        border: 4px solid #4c6566;
    }
    .mid{
        padding-top: 2rem;
        padding-left: 1rem;
        width: 220px;
        height: 310px;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        background-color: #4c6566;
        cursor: pointer;
    }
    .mid:hover{
        background-color: #549F93;
        transform: scale(1.1);
    }
    .right{
        padding-top: 2rem;
        padding-left: 1rem;
        width: 220px;
        height: 310px;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        background-color: #4c6566;
        cursor: pointer;
    }
    .right:hover{
        background-color: #549F93;
        transform: scale(1.1);
    }
}
@media only screen and (min-width: 1150px){
    .plans{
        display: flex;
        gap: 2rem;
        color: white;
        background-color: #104547;
        margin-top: -17vh;
        width: 100vw;
        justify-content: center;
        padding-top: 10vh;
        padding-bottom: 10vh;
    }
    .left{
        padding-top: 2rem;
        padding-left: 1rem;
        width: 300px;
        height: 420px;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        background-color: #4c6566;
        cursor: pointer;
    }
    .left:hover{
        background-color: #549F93;
        transform: scale(1.1);
    }
    .plan-discription{
        font-size: 1.3rem;
        font-weight: 600;
    }
    .price{
        font-size: 3.5rem;
        margin-top: -0px;
    }
    .lii{
        list-style: none;
        font-size: larger;
        list-style: georgian;
        padding-bottom: 1rem;
    }
    .p{
        font-size: large;
    }
    .btn{
        width: 285px;
        height: 40px;
        cursor: pointer;
        background-color: #4c6566;
        border:4px solid #549F93;
        color: white;
    }
    .btn:hover{
        background-color: #104547;
        border: 4px solid #4c6566;
    }
    .mid{
        padding-top: 2rem;
        padding-left: 1rem;
        width: 300px;
        height: 420px;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        background-color: #4c6566;
        cursor: pointer;
    }
    .mid:hover{
        background-color: #549F93;
        transform: scale(1.1);
    }
    .right{
        padding-top: 2rem;
        padding-left: 1rem;
        width: 300px;
        height: 420px;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        background-color: #4c6566;
        cursor: pointer;
    }
    .right:hover{
        background-color: #549F93;
        transform: scale(1.1);
    }
}