.heroSec{
    display: flex;
    justify-content: space-between;
    background: linear-gradient(
    to right,
    #104547 0%,
    #104547 80%,
    #549F93 80%,
    #549F93 100%
  );
    color: #ffffff;
    height: 125vh;
    width: 100vw;
    overflow: hidden;
    padding-bottom: 10vh;
}
.hero-Left{
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.the-best-ad{
  margin-top: 4rem;
  background-color: #549F93;
  width: fit-content;
  border-radius: 4rem;
  padding: 1rem;
  font-size: larger;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.the-best-add-animation{
  position: absolute;
  width: 5.7rem;
  height: 3rem;
  border-radius: 3rem;
  left: 9px;
  background-color: #aca8ae;
  z-index: 1;
  animation: run 5s  1s linear infinite;
}
@keyframes run {
  0% {
    left: 0;
  }
  50% {
    left: 75%;
  }
  100% {
    left: 0;    
  }
}
.the-best-ad>span{
  z-index: 2;
}
.hero-text{
  display: flex;
  flex-direction: column;
  font-size: 4.5rem;
  font-weight: bold;
}
.hero----text>span{
  font-size: 18px;
  font-weight: 200;
  text-transform: none;
  letter-spacing: 1px;
}
.hero----text{
  margin-top: -35px;
}
.figures{
  display: flex;
  gap: 2rem;
}
.figures1{
  display: flex;
  flex-direction: column;
}
.figures2{
  display: flex;
  flex-direction: column;
}
.figures3{
  display: flex;
  flex-direction: column;
}
.figures1-span1{
  font-size: 2.5rem;
}
.figures2-span1{
  font-size: 2.5rem;
}
.figures3-span1{
  font-size: 2.5rem;
}
.figures1-span2{
  font-size: 1rem;
}
.figures2-span2{
  font-size: 1rem;
}
.figures3-span2{
  font-size: 1rem;
}
.figures4{
  margin-top: -28px;
  background-color: #4c6566;
  border-radius: 5px;
  height: 100px;
  width: 240px;
}
.figures42{
  display: flex;
  padding-top: 17px;
  padding-left: 10px;
  border-radius: 5px;
  position: relative;
}
.kcal-img{
  width: 70px;
  height: 70px;
  border-radius: 50%;
}
.figures4-spans{
  display: flex;
  padding-left: 25px;
  flex-direction: column;
  gap: 15px;
}
.figures4-span1{
  font-size: 1.1rem;
}
.figures4-span2{
  font-size: 1.5rem;
}
.Hero-Right{
  position: absolute;
  margin-left: -10vh;
}
.bttn{
  display: flex;
  position: relative;
  top: 2.5rem;
  left: 88vw;

}
.Join{
  font-size: 1rem;
  color: white;
  background-color: #549F93;
  padding: 6px 15px 6px 15px;
  border: 2px solid #104547;
  cursor: pointer;
  font-weight: 600;
  
}
.heart{
  display: flex;
  flex-direction: column;
  gap: 1rem ;
  height: 120px;
  background-color: #104547;
  border-radius: 5px;
  padding-top: 15px;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 15px;
  top: 115px;
  position: relative;
}

.heart-img{
  width: 30px;
  height: 30px;
}
.heart-rate{
  font-size: 1rem;
}
.bpm{
  font-size: 1.5rem;
}
.Join:hover{
  background-color: #104547;
}
.hero-back-img{
  width: 320px;
  height: 450px;
  position: absolute;
  right: 190px;
  top: 70px;
  animation: slide 3s  1ms linear backwards;

}

@keyframes slide {
  0% {
    right: 0;
  }
  
  100% {
    right: 15%;    
  }
}
.hero-img{
  width: 300px;
  height: 450px;
  position: absolute;
  right: 120px;
  top: 200px;
  z-index: 3;
}

.hero-img{
  position: absolute;
  animation: slidein  3s   linear backwards;
}
@keyframes slidein {
  0% {
    right: 0;
  }
  
  100% {
    right: 10%;    
  }
}

  .strok-text{
    color: transparent;
    font-family: Arial, Helvetica, sans-serif;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: white;
    font-size: 4.5rem;
    font-weight: bold;
  }
  .butttons{
    display: flex;
    gap: 1rem;
    padding-top: 10vh;
  }
  
  .btn2:hover{
    background-color: #549F93;
  }
  .btnn1{
    background-color: #104547;
    border: 3px solid #549F93;
    padding: 10px 18px 10px 18px;
    color: white;
    font-size: 1rem;
    cursor: pointer;
  }
  .btnn2:hover{
    background-color: #549F93;
  }
  .btnn1:hover{
    background-color: #549F93;
  }
  .btnn2{
    background-color: #104547;
    border: 3px solid #549F93;
    padding: 10px 18px 10px 18px;
    color: white;
    font-size: 1rem;
    cursor: pointer;
  }
  @media only screen and (min-width: 200px){
    .heroSec{
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      background: linear-gradient(
      to right,
      #104547 0%,
      #104547 80%,
      #549F93 80%,
      #549F93 100%
    );
      color: #ffffff;
      height: 95vh;
      width: 100vw;
      overflow: hidden;
      padding-bottom: 10vh;
  }
  .hero-Left{
    padding: 0.5rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  .the-best-ad{
    margin-top: 4rem;
    background-color: #549F93;
    width: fit-content;
    border-radius: 4rem;
    padding: 1rem;
    font-size: 11px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  .the-best-add-animation{
    position: absolute;
    width: 4rem;
    height: 2.5rem;
    border-radius: 3rem;
    left: 9px;
    background-color: #aca8ae;
    z-index: 1;
    animation: run 5s  1s linear infinite;
  }
  .hero-text{
    display: flex;
    flex-direction: column;
    font-size: 2rem;
    font-weight: bold;
  }
  .hero----text>span{
    margin-top: 5px;
    padding-bottom: 10px;
    height: 50px;
    font-size: 12px;
    font-weight: 200;
    text-transform: none;
    letter-spacing: 1px;
  }
  .strok-text{
    color: transparent;
    font-family: Arial, Helvetica, sans-serif;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: white;
    font-size: 2rem;
    font-weight: bold;
  }
  .figures{
    display: flex;
    gap: 0.7rem;
  }
  .figures1{
    display: flex;
    flex-direction: column;
  }
  .figures2{
    display: flex;
    flex-direction: column;
  }
  .figures3{
    display: flex;
    flex-direction: column;
  }
  .figures1-span1{
    font-size: 1rem;
  }
  .figures2-span1{
    font-size: 1rem;
  }
  .figures3-span1{
    font-size: 1rem;
  }
  .figures1-span2{
    font-size: 0.5rem;
  }
  .figures2-span2{
    font-size: 0.5rem;
  }
  .figures3-span2{
    font-size: 0.5rem;
  }
  .figures4{
    margin-top: -8px;
    background-color: #4c6566;
    border-radius: 5px;
    height: 50px;
    width: 110px;
  }
  .figures42{
    display: flex;
    padding-top: 10px;
    padding-left: 10px;
    border-radius: 5px;
    position: relative;
  }
  .kcal-img{
    width: 20px;
    height: 20px;
    border-radius: 50%;
  }
  .figures4-spans{
    display: flex;
    padding-left: 10px;
    flex-direction: column;
    gap: 5px;
  }
  .figures4-span1{
    font-size: 0.5rem;
  }
  .figures4-span2{
    font-size: 0.7rem;
  }
  .Hero-Right{
    position: absolute;
    margin-left: -10vh;
  }
  .bttn{
    display: flex;
    position: relative;
    top: 2rem;
    left: 97vw;
  
  }
  .Join{
    font-size: 0.5rem;
    color: white;
    background-color: #549F93;
    padding: 4px 10px 4px 10px;
    border: 2px solid #104547;
    cursor: pointer;
    font-weight: 600;
    position: relative;
    top: 50vh;
    left: 5vw;
  }
  .heart{
    display: flex;
    flex-direction: column;
    gap: 0.5rem ;
    height: 55px;
    background-color: #549F93;
    border-radius: 5px;
    padding-top: 15px;
    padding-left: 15px;
    padding-right: 15px;
    height: 70px;
    padding-bottom: 15px;
    top: 74vh;
    left: 5vw;
    position: absolute;
  }
  
  .heart-img{
    width: 30px;
    height: 30px;
  }
  .heart-rate{
    font-size: 0.7rem;
  }
  .bpm{
    font-size: 0.8rem;
  }
  .Join:hover{
    background-color: #104547;
  }
  .hero-back-img{
    width: 150px;
    height: 250px;
    position: absolute;
    right: 70px;
    top: 57vh;
    animation: slide 3s  1ms linear backwards;
  
  }
  @keyframes slide {
    0% {
      right: 0;
    }
    
    100% {
      right: 17%;    
    }
  }
  .hero-img{
    width: 150px;
    height: 250px;
    position: absolute;
    right: 40px;
    top: 65vh;
    z-index: 3;
  }
  
  .hero-img{
    position: absolute;
    animation: slidein  3s   linear backwards;
  }
  @keyframes slidein {
    0% {
      right: 0;
    }
    
    100% {
      right: 10%;    
    }
  }
  .butttons{
    display: flex;
    gap: 0.5rem;
    padding-top: 3vh;
  }
  
  .btn2:hover{
    background-color: #549F93;
  }
  .btnn1{
    background-color: #104547;
    border: 3px solid #549F93;
    padding: 8px 13px 8px 13px;
    color: white;
    font-size: 0.7rem;
    cursor: pointer;
  }
  .btnn2:hover{
    background-color: #549F93;
  }
  .btnn1:hover{
    background-color: #549F93;
  }
  .btnn2{
    background-color: #104547;
    border: 3px solid #549F93;
    padding: 8px 13px 8px 13px;
    color: white;
    font-size: 0.7rem;
    cursor: pointer;
  }
  }
  
  @media only screen and (min-width: 400px){
    .heroSec{
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      background: linear-gradient(
      to right,
      #104547 0%,
      #104547 80%,
      #549F93 80%,
      #549F93 100%
    );
      color: #ffffff;
      height: 90vh;
      width: 100vw;
      overflow: hidden;
      padding-bottom: 10vh;
  }
  .hero-Left{
    padding: 0.5rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  .the-best-ad{
    margin-top: 4rem;
    background-color: #549F93;
    width: fit-content;
    border-radius: 4rem;
    padding: 1rem;
    font-size: 14px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  .the-best-add-animation{
    position: absolute;
    width: 4rem;
    height: 2.5rem;
    border-radius: 3rem;
    left: 9px;
    background-color: #aca8ae;
    z-index: 1;
    animation: run 5s  1s linear infinite;
  }
  .hero-text{
    display: flex;
    flex-direction: column;
    font-size: 2rem;
    font-weight: bold;
  }
  .hero----text>span{
    margin-top: 5px;
    padding-bottom: 10px;
    height: 50px;
    font-size: 12px;
    font-weight: 200;
    text-transform: none;
    letter-spacing: 1px;
  }
  .strok-text{
    color: transparent;
    font-family: Arial, Helvetica, sans-serif;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: white;
    font-size: 2rem;
    font-weight: bold;
  }
  .figures{
    display: flex;
    gap: 0.7rem;
  }
  .figures1{
    display: flex;
    flex-direction: column;
  }
  .figures2{
    display: flex;
    flex-direction: column;
  }
  .figures3{
    display: flex;
    flex-direction: column;
  }
  .figures1-span1{
    font-size: 1rem;
  }
  .figures2-span1{
    font-size: 1rem;
  }
  .figures3-span1{
    font-size: 1rem;
  }
  .figures1-span2{
    font-size: 0.5rem;
  }
  .figures2-span2{
    font-size: 0.5rem;
  }
  .figures3-span2{
    font-size: 0.5rem;
  }
  .figures4{
    margin-top: -28px;
    background-color: #4c6566;
    border-radius: 5px;
    height: 65px;
    width: 150px;
  }
  .figures42{
    display: flex;
    padding-top: 17px;
    padding-left: 10px;
    border-radius: 5px;
    position: relative;
  }
  .kcal-img{
    width: 30px;
    height: 30px;
    border-radius: 50%;
  }
  .figures4-spans{
    display: flex;
    padding-left: 10px;
    flex-direction: column;
    gap: 5px;
  }
  .figures4-span1{
    font-size: 0.7rem;
  }
  .figures4-span2{
    font-size: 1rem;
  }
  .Hero-Right{
    position: absolute;
    margin-left: -10vh;
  }
  .bttn{
    display: flex;
    position: relative;
    top: 2rem;
    left: 97vw;
  
  }
  .Join{
    font-size: 0.65rem;
    color: white;
    background-color: #549F93;
    padding: 5px 11px 5px 11px;
    border: 2px solid #104547;
    cursor: pointer;
    font-weight: 600;
    position: relative;
    top: 57vh;
    left: 0vw;
  }
  .heart{
    display: flex;
    flex-direction: column;
    gap: 0.5rem ;
    height: 55px;
    background-color: #549F93;
    border-radius: 5px;
    padding-top: 15px;
    padding-left: 15px;
    padding-right: 15px;
    height: 70px;
    padding-bottom: 15px;
    top: 74vh;
    left: 20vw;
    position: absolute;
  }
  
  .heart-img{
    width: 30px;
    height: 30px;
  }
  .heart-rate{
    font-size: 0.7rem;
  }
  .bpm{
    font-size: 0.8rem;
  }
  .Join:hover{
    background-color: #104547;
  }
  .hero-back-img{
    width: 150px;
    height: 250px;
    position: absolute;
    right: 70px;
    top: 57vh;
    animation: slide 3s  1ms linear backwards;
  
  }
  @keyframes slide {
    0% {
      right: 0;
    }
    
    100% {
      right: 17%;    
    }
  }
  .hero-img{
    width: 150px;
    height: 250px;
    position: absolute;
    right: 40px;
    top: 65vh;
    z-index: 3;
  }
  
  .hero-img{
    position: absolute;
    animation: slidein  3s   linear backwards;
  }
  @keyframes slidein {
    0% {
      right: 0;
    }
    
    100% {
      right: 10%;    
    }
  }
  .butttons{
    display: flex;
    gap: 0.5rem;
    padding-top: 3vh;
  }
  
  .btn2:hover{
    background-color: #549F93;
  }
  .btnn1{
    background-color: #104547;
    border: 3px solid #549F93;
    padding: 8px 13px 8px 13px;
    color: white;
    font-size: 0.7rem;
    cursor: pointer;
  }
  .btnn2:hover{
    background-color: #549F93;
  }
  .btnn1:hover{
    background-color: #549F93;
  }
  .btnn2{
    background-color: #104547;
    border: 3px solid #549F93;
    padding: 8px 13px 8px 13px;
    color: white;
    font-size: 0.7rem;
    cursor: pointer;
  }
  }
  @media only screen and (min-width: 600px){
    .heroSec{
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      background: linear-gradient(
      to right,
      #104547 0%,
      #104547 80%,
      #549F93 80%,
      #549F93 100%
    );
      color: #ffffff;
      height: 83vh;
      width: 100vw;
      overflow: hidden;
      padding-bottom: 10vh;
  }
  .hero-Left{
    padding: 0.5rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  .the-best-ad{
    margin-top: 4rem;
    background-color: #549F93;
    width: fit-content;
    border-radius: 4rem;
    padding: 1rem;
    font-size: 15px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  .the-best-add-animation{
    position: absolute;
    width: 4rem;
    height: 2.5rem;
    border-radius: 3rem;
    left: 9px;
    background-color: #aca8ae;
    z-index: 1;
    animation: run 5s  1s linear infinite;
  }
  .hero-text{
    display: flex;
    flex-direction: column;
    font-size: 2rem;
    font-weight: bold;
  }
  .hero----text>span{
    margin-top: 5px;
    padding-bottom: 10px;
    height: 50px;
    font-size: 12px;
    font-weight: 200;
    text-transform: none;
    letter-spacing: 1px;
  }
  .strok-text{
    color: transparent;
    font-family: Arial, Helvetica, sans-serif;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: white;
    font-size: 2rem;
    font-weight: bold;
  }
  .figures{
    display: flex;
    gap: 0.7rem;
  }
  .figures1{
    display: flex;
    flex-direction: column;
  }
  .figures2{
    display: flex;
    flex-direction: column;
  }
  .figures3{
    display: flex;
    flex-direction: column;
  }
  .figures1-span1{
    font-size: 1rem;
  }
  .figures2-span1{
    font-size: 1rem;
  }
  .figures3-span1{
    font-size: 1rem;
  }
  .figures1-span2{
    font-size: 0.5rem;
  }
  .figures2-span2{
    font-size: 0.5rem;
  }
  .figures3-span2{
    font-size: 0.5rem;
  }
  .figures4{
    margin-top: -28px;
    background-color: #4c6566;
    border-radius: 5px;
    height: 65px;
    width: 150px;
  }
  .figures42{
    display: flex;
    padding-top: 17px;
    padding-left: 10px;
    border-radius: 5px;
    position: relative;
  }
  .kcal-img{
    width: 30px;
    height: 30px;
    border-radius: 50%;
  }
  .figures4-spans{
    display: flex;
    padding-left: 10px;
    flex-direction: column;
    gap: 5px;
  }
  .figures4-span1{
    font-size: 0.7rem;
  }
  .figures4-span2{
    font-size: 1rem;
  }
  .Hero-Right{
    position: absolute;
    margin-left: -10vh;
  }
  .bttn{
    display: flex;
    position: relative;
    top: 2rem;
    left: 97vw;
  
  }
  .Join{
    font-size: 0.7rem;
    color: white;
    background-color: #549F93;
    padding: 6px 13px 6px 13px;
    border: 2px solid #104547;
    cursor: pointer;
    font-weight: 600;
    position: relative;
    left: 1vw;
    top: 40vh;
  }
  .heart{
    display: flex;
    flex-direction: column;
    gap: 0.5rem ;
    height: 55px;
    background-color: #549F93;
    border-radius: 5px;
    padding-top: 15px;
    padding-left: 15px;
    padding-right: 15px;
    height: 86px;
    padding-bottom: 15px;
    top: 55vh;
    left: 15vw;
    position: absolute;
  }
  
  .heart-img{
    width: 30px;
    height: 30px;
  }
  .heart-rate{
    font-size: 1rem;
  }
  .bpm{
    font-size: 1.2rem;
  }
  .Join:hover{
    background-color: #104547;
  }
  .hero-back-img{
    width: 200px;
    height: 320px;
    position: absolute;
    right: 130px;
    top: 37vh;
    animation: slide 3s  1ms linear backwards;
  
  }
  @keyframes slide {
    0% {
      right: 0;
    }
    
    100% {
      right: 22%;    
    }
  }
  .hero-img{
    width: 200px;
    height: 320px;
    position: absolute;
    right: 70px;
    top: 44vh;
    z-index: 3;
  }
  
  .hero-img{
    position: absolute;
    animation: slidein  3s   linear backwards;
  }
  @keyframes slidein {
    0% {
      right: 0;
    }
    
    100% {
      right: 12%;    
    }
  }
  .butttons{
    display: flex;
    gap: 0.5rem;
    padding-top: 3vh;
  }
  
  .btn2:hover{
    background-color: #549F93;
  }
  .btnn1{
    background-color: #104547;
    border: 3px solid #549F93;
    padding: 8px 13px 8px 13px;
    color: white;
    font-size: 0.7rem;
    cursor: pointer;
  }
  .btnn2:hover{
    background-color: #549F93;
  }
  .btnn1:hover{
    background-color: #549F93;
  }
  .btnn2{
    background-color: #104547;
    border: 3px solid #549F93;
    padding: 8px 13px 8px 13px;
    color: white;
    font-size: 0.7rem;
    cursor: pointer;
  }
  }
  
  @media only screen and (min-width: 769px){
    .heroSec{
      display: flex;
      justify-content: space-between;
      background: linear-gradient(
      to right,
      #104547 0%,
      #104547 80%,
      #549F93 80%,
      #549F93 100%
    );
      color: #ffffff;
      height: 60vh;
      width: 100vw;
      overflow: hidden;
      padding-bottom: 10vh;
  }
  .hero-Left{
    padding: 0.5rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  .the-best-ad{
    margin-top: 4rem;
    background-color: #549F93;
    width: fit-content;
    border-radius: 4rem;
    padding: 1rem;
    font-size: 15px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  .the-best-add-animation{
    position: absolute;
    width: 4rem;
    height: 2.5rem;
    border-radius: 3rem;
    left: 9px;
    background-color: #aca8ae;
    z-index: 1;
    animation: run 5s  1s linear infinite;
  }
  .hero-text{
    display: flex;
    flex-direction: column;
    font-size: 2rem;
    font-weight: bold;
  }
  .hero----text>span{
    margin-top: 5px;
    padding-bottom: 10px;
    height: 50px;
    font-size: 12px;
    font-weight: 200;
    text-transform: none;
    letter-spacing: 1px;
  }
  .strok-text{
    color: transparent;
    font-family: Arial, Helvetica, sans-serif;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: white;
    font-size: 2rem;
    font-weight: bold;
  }
  .figures{
    display: flex;
    gap: 0.7rem;
  }
  .figures1{
    display: flex;
    flex-direction: column;
  }
  .figures2{
    display: flex;
    flex-direction: column;
  }
  .figures3{
    display: flex;
    flex-direction: column;
  }
  .figures1-span1{
    font-size: 1rem;
  }
  .figures2-span1{
    font-size: 1rem;
  }
  .figures3-span1{
    font-size: 1rem;
  }
  .figures1-span2{
    font-size: 0.5rem;
  }
  .figures2-span2{
    font-size: 0.5rem;
  }
  .figures3-span2{
    font-size: 0.5rem;
  }
  .figures4{
    margin-top: -28px;
    background-color: #4c6566;
    border-radius: 5px;
    height: 65px;
    width: 150px;
  }
  .figures42{
    display: flex;
    padding-top: 17px;
    padding-left: 10px;
    border-radius: 5px;
    position: relative;
  }
  .kcal-img{
    width: 30px;
    height: 30px;
    border-radius: 50%;
  }
  .figures4-spans{
    display: flex;
    padding-left: 10px;
    flex-direction: column;
    gap: 5px;
  }
  .figures4-span1{
    font-size: 0.7rem;
  }
  .figures4-span2{
    font-size: 1rem;
  }
  .Hero-Right{
    position: absolute;
    margin-left: -10vh;
  }
  .bttn{
    display: flex;
    position: relative;
    top: 2rem;
    left: 97vw;
  
  }
  .Join{
    font-size: 0.7rem;
    color: white;
    background-color: #549F93;
    padding: 6px 13px 6px 13px;
    border: 2px solid #104547;
    cursor: pointer;
    font-weight: 600;
    position: relative;
    top: -5px;
    left: 1vw;
  }
  .heart{
    display: flex;
    flex-direction: column;
    gap: 0.5rem ;
    height: 70px;
    background-color: #104547;
    border-radius: 5px;
    padding-top: 15px;
    padding-left: 15px;
    padding-right: 15px;
    height: 70px;
    padding-bottom: 15px;
    left: 85vw;
    top: 115px;
    position: absolute;
  }
  
  .heart-img{
    width: 25px;
    height: 25px;
  }
  .heart-rate{
    font-size: 0.7rem;
  }
  .bpm{
    font-size: 1.0rem;
  }
  .Join:hover{
    background-color: #104547;
  }
  .hero-back-img{
    width: 180px;
    height: 270px;
    position: absolute;
    right: 130px;
    top: 100px;
    animation: slide 3s  1ms linear backwards;
  
  }
  @keyframes slide {
    0% {
      right: 0;
    }
    
    100% {
      right: 17%;    
    }
  }
  .hero-img{
    width: 170px;
    height: 270px;
    position: absolute;
    right: 80px;
    top: 200px;
    z-index: 3;
  }
  
  .hero-img{
    position: absolute;
    animation: slidein  3s   linear backwards;
  }
  @keyframes slidein {
    0% {
      right: 0;
    }
    
    100% {
      right: 10%;    
    }
  }
  .butttons{
    display: flex;
    gap: 0.5rem;
    padding-top: 3vh;
  }
  
  .btn2:hover{
    background-color: #549F93;
  }
  .btnn1{
    background-color: #104547;
    border: 3px solid #549F93;
    padding: 8px 13px 8px 13px;
    color: white;
    font-size: 0.7rem;
    cursor: pointer;
  }
  .btnn2:hover{
    background-color: #549F93;
  }
  .btnn1:hover{
    background-color: #549F93;
  }
  .btnn2{
    background-color: #104547;
    border: 3px solid #549F93;
    padding: 8px 13px 8px 13px;
    color: white;
    font-size: 0.7rem;
    cursor: pointer;
  }
  }
  @media only screen and (min-width: 900px){
    .heroSec{
      display: flex;
      justify-content: space-between;
      background: linear-gradient(
      to right,
      #104547 0%,
      #104547 80%,
      #549F93 80%,
      #549F93 100%
    );
      color: #ffffff;
      height: 70vh;
      width: 100vw;
      overflow: hidden;
      padding-bottom: 10vh;
  }
  .hero-Left{
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }
  .the-best-ad{
    margin-top: 4rem;
    background-color: #549F93;
    width: fit-content;
    border-radius: 4rem;
    padding: 1rem;
    font-size: large;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  .the-best-add-animation{
    position: absolute;
    width: 4rem;
    height: 2.5rem;
    border-radius: 3rem;
    left: 9px;
    background-color: #aca8ae;
    z-index: 1;
    animation: run 5s  1s linear infinite;
  }
  .hero-text{
    display: flex;
    flex-direction: column;
    font-size: 3rem;
    font-weight: bold;
  }
  .hero----text>span{
    font-size: 12px;
    font-weight: 200;
    text-transform: none;
    letter-spacing: 1px;
  }
  .strok-text{
    color: transparent;
    font-family: Arial, Helvetica, sans-serif;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: white;
    font-size: 3rem;
    font-weight: bold;
  }
  .figures{
    display: flex;
    gap: 1rem;
  }
  .figures1{
    display: flex;
    flex-direction: column;
  }
  .figures2{
    display: flex;
    flex-direction: column;
  }
  .figures3{
    display: flex;
    flex-direction: column;
  }
  .figures1-span1{
    font-size: 1.5rem;
  }
  .figures2-span1{
    font-size: 1.5rem;
  }
  .figures3-span1{
    font-size: 1.5rem;
  }
  .figures1-span2{
    font-size: 0.7rem;
  }
  .figures2-span2{
    font-size: 0.7rem;
  }
  .figures3-span2{
    font-size: 0.7rem;
  }
  .figures4{
    margin-top: -28px;
    background-color: #4c6566;
    border-radius: 5px;
    height: 80px;
    width: 150px;
  }
  .figures42{
    display: flex;
    padding-top: 17px;
    padding-left: 10px;
    border-radius: 5px;
    position: relative;
  }
  .kcal-img{
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }
  .figures4-spans{
    display: flex;
    padding-left: 10px;
    flex-direction: column;
    gap: 5px;
  }
  .figures4-span1{
    font-size: 0.7rem;
  }
  .figures4-span2{
    font-size: 1.2rem;
  }
  .Hero-Right{
    position: absolute;
    margin-left: -10vh;
  }
  .bttn{
    display: flex;
    position: relative;
    top: 2rem;
    left: 97vw;
  
  }
  .Join{
    font-size: 0.8rem;
    color: white;
    background-color: #549F93;
    padding: 7px 15px 7px 15px;
    border: 2px solid #104547;
    cursor: pointer;
    font-weight: 600;
    
  }
  .heart{
    display: flex;
    flex-direction: column;
    gap: 0.5rem ;
    height: 70px;
    background-color: #104547;
    border-radius: 5px;
    padding-top: 15px;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 15px;
    position: absolute;
  }
  
  .heart-img{
    width: 20px;
    height: 20px;
  }
  .heart-rate{
    font-size: 0.7rem;
  }
  .bpm{
    font-size: 1.0rem;
  }
  .Join:hover{
    background-color: #104547;
  }
  .hero-back-img{
    width: 250px;
    height: 350px;
    position: absolute;
    right: 130px;
    top: 100px;
    animation: slide 3s  1ms linear backwards;
  
  }
  @keyframes slide {
    0% {
      right: 0;
    }
    
    100% {
      right: 15%;    
    }
  }
  .hero-img{
    width: 230px;
    height: 350px;
    position: absolute;
    right: 80px;
    top: 200px;
    z-index: 3;
  }
  
  .hero-img{
    position: absolute;
    animation: slidein  3s   linear backwards;
  }
  @keyframes slidein {
    0% {
      right: 0;
    }
    
    100% {
      right: 9%;    
    }
  }
  .butttons{
    display: flex;
    gap: 0.5rem;
    padding-top: 3vh;
  }
  
  .btn2:hover{
    background-color: #549F93;
  }
  .btnn1{
    background-color: #104547;
    border: 3px solid #549F93;
    padding: 8px 13px 8px 13px;
    color: white;
    font-size: 1rem;
    cursor: pointer;
  }
  .btnn2:hover{
    background-color: #549F93;
  }
  .btnn1:hover{
    background-color: #549F93;
  }
  .btnn2{
    background-color: #104547;
    border: 3px solid #549F93;
    padding: 8px 13px 8px 13px;
    color: white;
    font-size: 1rem;
    cursor: pointer;
  }
  }
  @media only screen and (min-width: 1000px){
    .heart{
      display: flex;
      flex-direction: column;
      gap: 0.5rem ;
      height: 70px;
      background-color: #104547;
      border-radius: 5px;
      padding-top: 15px;
      padding-left: 15px;
      padding-right: 15px;
      padding-bottom: 15px;
      position: absolute;
    }
  }
  @media only screen and (min-width: 1100px){
    .heart{
      display: flex;
      flex-direction: column;
      gap: 0.5rem ;
      height: 70px;
      background-color: #104547;
      border-radius: 5px;
      padding-top: 15px;
      padding-left: 15px;
      padding-right: 15px;
      padding-bottom: 15px;
      position: absolute;
    }
  }
  @media only screen and (min-width: 1150px){
    .heroSec{
      display: flex;
      justify-content: space-between;
      background: linear-gradient(
      to right,
      #104547 0%,
      #104547 80%,
      #549F93 80%,
      #549F93 100%
    );
      color: #ffffff;
      height: 125vh;
      width: 100vw;
      overflow: hidden;
      padding-bottom: 10vh;
  }
  .hero-Left{
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }
  .the-best-ad{
    margin-top: 4rem;
    background-color: #549F93;
    width: fit-content;
    border-radius: 4rem;
    padding: 1rem;
    font-size: larger;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  .the-best-add-animation{
    position: absolute;
    width: 5.7rem;
    height: 3rem;
    border-radius: 3rem;
    left: 9px;
    background-color: #aca8ae;
    z-index: 1;
    animation: run 5s  1s linear infinite;
  }
  @keyframes run {
    0% {
      left: 0;
    }
    50% {
      left: 75%;
    }
    100% {
      left: 0;    
    }
  }
  .the-best-ad>span{
    z-index: 2;
  }
  .hero-text{
    display: flex;
    flex-direction: column;
    font-size: 4.5rem;
    font-weight: bold;
  }
  .hero----text>span{
    font-size: 18px;
    font-weight: 200;
    text-transform: none;
    letter-spacing: 1px;
  }
  .hero----text{
    margin-top: -35px;
  }
  .figures{
    display: flex;
    gap: 2rem;
  }
  .figures1{
    display: flex;
    flex-direction: column;
  }
  .figures2{
    display: flex;
    flex-direction: column;
  }
  .figures3{
    display: flex;
    flex-direction: column;
  }
  .figures1-span1{
    font-size: 2.5rem;
  }
  .figures2-span1{
    font-size: 2.5rem;
  }
  .figures3-span1{
    font-size: 2.5rem;
  }
  .figures1-span2{
    font-size: 1rem;
  }
  .figures2-span2{
    font-size: 1rem;
  }
  .figures3-span2{
    font-size: 1rem;
  }
  .figures4{
    margin-top: -28px;
    background-color: #4c6566;
    border-radius: 5px;
    height: 100px;
    width: 240px;
  }
  .figures42{
    display: flex;
    padding-top: 17px;
    padding-left: 10px;
    border-radius: 5px;
    position: relative;
  }
  .kcal-img{
    width: 70px;
    height: 70px;
    border-radius: 50%;
  }
  .figures4-spans{
    display: flex;
    padding-left: 25px;
    flex-direction: column;
    gap: 15px;
  }
  .figures4-span1{
    font-size: 1.1rem;
  }
  .figures4-span2{
    font-size: 1.5rem;
  }
  .Hero-Right{
    position: absolute;
    margin-left: -10vh;
  }
  .bttn{
    display: flex;
    position: relative;
    top: 2.5rem;
    left: 88vw;
  
  }
  .Join{
    position: relative;
    font-size: 1rem;
    color: white;
    background-color: #549F93;
    padding: 6px 15px 6px 15px;
    border: 2px solid #104547;
    cursor: pointer;
    font-weight: 600;
    right: -10vw;
  }
  .heart{
    display: flex;
    flex-direction: column;
    gap: 0.5rem ;
    height: 110px;
    background-color: #104547;
    border-radius: 5px;
    padding-top: 15px;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 15px;
    position: absolute;
  }
  
  .heart-img{
    width: 30px;
    height: 30px;
  }
  .heart-rate{
    font-size: 1rem;
  }
  .bpm{
    font-size: 1.5rem;
  }
  .Join:hover{
    background-color: #104547;
  }
  .hero-back-img{
    width: 320px;
    height: 450px;
    position: absolute;
    right: 190px;
    top: 70px;
    animation: slide 3s  1ms linear backwards;
  
  }
  
  @keyframes slide {
    0% {
      right: 0;
    }
    
    100% {
      right: 15%;    
    }
  }
  .hero-img{
    width: 300px;
    height: 450px;
    position: absolute;
    right: 120px;
    top: 200px;
    z-index: 3;
  }
  
  .hero-img{
    position: absolute;
    animation: slidein  3s   linear backwards;
  }
  @keyframes slidein {
    0% {
      right: 0;
    }
    
    100% {
      right: 10%;    
    }
  }
  
    .strok-text{
      color: transparent;
      font-family: Arial, Helvetica, sans-serif;
      -webkit-text-stroke-width: 1px;
      -webkit-text-stroke-color: white;
      font-size: 4.5rem;
      font-weight: bold;
    }
    .butttons{
      display: flex;
      gap: 1rem;
      padding-top: 10vh;
    }
    
    .btn2:hover{
      background-color: #549F93;
    }
    .btnn1{
      background-color: #104547;
      border: 3px solid #549F93;
      padding: 10px 18px 10px 18px;
      color: white;
      font-size: 1rem;
      cursor: pointer;
    }
    .btnn2:hover{
      background-color: #549F93;
    }
    .btnn1:hover{
      background-color: #549F93;
    }
    .btnn2{
      background-color: #104547;
      border: 3px solid #549F93;
      padding: 10px 18px 10px 18px;
      color: white;
      font-size: 1rem;
      cursor: pointer;
    }
  }