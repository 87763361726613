.join{
    margin-top: -17vh;
    padding-top: 15vh;
    background-color: #104547;
    padding-left: 2rem;
    display: flex;
    padding-bottom: 10vh;
}
.line{
    width: 400px;
    height: 20px;
    border-radius: 30px;
    margin-bottom: 30px;
    background-color: #2ef5ff;
    border: 2px solid white;
}
.mail{
    margin-left: 15rem;
    margin-top: 5rem;
    padding-right: 1rem;
    display: flex;
    height: 80px;
    padding-left: 1rem;
    width: 300px;
    justify-content: center;
    align-items: center;
    background-color: #4c6566;
    border-radius: 5px;
}
.email{
    width: 200px;
    height: 50px;
    border-radius: 5px;
    margin-right: 5px;
    background-color: #4c6566;
    border: 3px solid #4c6566;
}
.email::placeholder{
    color: darkgray;
}
.btn-email{
    width: 80px;
    height: 40px;
    border-radius: 5px;
    background-color: #104547;
    border: 4px solid #104547;
    cursor: pointer;
}
@media only screen and (min-width: 300px){
    .join{
        margin-top: -15vh;
        padding-top: 15vh;
        background-color: #104547;
        margin-left: -1rem;
        display: flex;
        flex-direction: column;
        padding-bottom: 10vh;
    }
    .line{
        width: 200px;
        height: 10px;
        border-radius: 30px;
        margin-bottom: 30px;
        background-color: #2ef5ff;
        border: 2px solid white;
    }
    .mail{
        margin-left: 1rem;
        margin-top: 5rem;
        padding-right: 1rem;
        display: flex;
        height: 70px;
        padding-left: 1rem;
        width: 250px;
        justify-content: center;
        align-items: center;
        background-color: #4c6566;
        border-radius: 5px;
    }
    .email{
        width: 150px;
        height: 50px;
        border-radius: 5px;
        margin-right: 5px;
        background-color: #4c6566;
        border: 3px solid #4c6566;
    }
    .email::placeholder{
        color: darkgray;
    }
    .btn-email{
        width: 80px;
        font-size: 12px;
        height: 40px;
        border-radius: 5px;
        background-color: #104547;
        border: 4px solid #104547;
        cursor: pointer;
    }
}
@media only screen and (min-width: 400px) {
    .join{
        margin-top: -10vh;
        padding-top: 15vh;
        background-color: #104547;
        margin-left: -1rem;
        display: flex;
        flex-direction: column;
        padding-bottom: 10vh;
    }
    .line{
        width: 300px;
        height: 17px;
        border-radius: 30px;
        margin-bottom: 30px;
        background-color: #2ef5ff;
        border: 2px solid white;
    }
    .mail{
        margin-left: 3rem;
        margin-top: 5rem;
        padding-right: 1rem;
        display: flex;
        height: 70px;
        padding-left: 1rem;
        width: 250px;
        justify-content: center;
        align-items: center;
        background-color: #4c6566;
        border-radius: 5px;
    }
    .email{
        width: 150px;
        height: 50px;
        border-radius: 5px;
        margin-right: 5px;
        background-color: #4c6566;
        border: 3px solid #4c6566;
    }
    .email::placeholder{
        color: darkgray;
    }
    .btn-email{
        width: 80px;
        font-size: 12px;
        height: 40px;
        border-radius: 5px;
        background-color: #104547;
        border: 4px solid #104547;
        cursor: pointer;
    }
}
@media only screen and (min-width: 500px){
    .join{
        margin-top: -12vh;
        padding-top: 15vh;
        background-color: #104547;
        padding-left: 2rem;
        display: flex;
        flex-direction: column;
        padding-right: 1rem;
        padding-bottom: 10vh;
    }
    .line{
        width: 300px;
        height: 17px;
        border-radius: 30px;
        margin-bottom: 30px;
        background-color: #2ef5ff;
        border: 2px solid white;
    }
    .mail{
        margin-left: 6rem;
        margin-top: 5rem;
        padding-right: 1rem;
        display: flex;
        height: 70px;
        padding-left: 1rem;
        width: 250px;
        justify-content: center;
        align-items: center;
        background-color: #4c6566;
        border-radius: 5px;
    }
    .email{
        width: 150px;
        height: 50px;
        border-radius: 5px;
        margin-right: 5px;
        background-color: #4c6566;
        border: 3px solid #4c6566;
    }
    .email::placeholder{
        color: darkgray;
    }
    .btn-email{
        width: 80px;
        font-size: 12px;
        height: 40px;
        border-radius: 5px;
        background-color: #104547;
        border: 4px solid #104547;
        cursor: pointer;
    } 
    .righttt{
        display: flex;
        width: 100vw;
        justify-content:  space-evenly;
    }
}

@media only screen and (min-width: 768px){
    .join{
        margin-top: -12vh;
        padding-top: 15vh;
        background-color: #104547;
        padding-left: 2rem;
        display: flex;
        flex-direction: row;
        padding-bottom: 10vh;
    }
    .line{
        width: 300px;
        height: 17px;
        border-radius: 30px;
        margin-bottom: 30px;
        background-color: #2ef5ff;
        border: 2px solid white;
    }
    .mail{
        margin-left: 6rem;
        margin-top: 1rem;
        padding-right: 1rem;
        display: flex;
        height: 70px;
        padding-left: 1rem;
        width: 250px;
        justify-content: center;
        align-items: center;
        background-color: #4c6566;
        border-radius: 5px;
    }
    .email{
        width: 150px;
        height: 50px;
        border-radius: 5px;
        margin-right: 5px;
        background-color: #4c6566;
        border: 3px solid #4c6566;
    }
    .email::placeholder{
        color: darkgray;
    }
    .btn-email{
        width: 80px;
        font-size: 12px;
        height: 40px;
        border-radius: 5px;
        background-color: #104547;
        border: 4px solid #104547;
        cursor: pointer;
    }   
}
@media only screen and (min-width: 900px){
    .join{
        margin-top: -12vh;
        padding-top: 15vh;
        background-color: #104547;
        padding-left: 2rem;
        display: flex;
        padding-bottom: 10vh;
    }
    .line{
        width: 300px;
        height: 17px;
        border-radius: 30px;
        margin-bottom: 30px;
        background-color: #2ef5ff;
        border: 2px solid white;
    }
    .righttt{
        margin-right: 2rem;
    }
    .mail{
        margin-left: 15rem;
        margin-top: 5rem;
        padding-right: 1rem;
        display: flex;
        height: 80px;
        padding-left: 1rem;
        width: 250px;
        justify-content: center;
        align-items: center;
        background-color: #4c6566;
        border-radius: 5px;
    }
    .email{
        width: 150px;
        height: 50px;
        border-radius: 5px;
        margin-right: 5px;
        background-color: #4c6566;
        border: 3px solid #4c6566;
    }
    .email::placeholder{
        color: darkgray;
    }
    .btn-email{
        width: 80px;
        font-size: 12px;
        height: 40px;
        border-radius: 5px;
        background-color: #104547;
        border: 4px solid #104547;
        cursor: pointer;
    }   
}
@media only screen and (min-width: 1000px){
    .mail{
        margin-left: 37vw;
    }
}
@media only screen and (min-width: 1150px){
    .join{
        margin-top: -17vh;
        padding-top: 15vh;
        background-color: #104547;
        padding-left: 2rem;
        display: flex;
        padding-bottom: 10vh;
    }
    .line{
        width: 400px;
        height: 20px;
        border-radius: 30px;
        margin-bottom: 30px;
        background-color: #2ef5ff;
        border: 2px solid white;
    }
    .mail{
        margin-left: 17rem;
        margin-top: 5rem;
        padding-right: 1rem;
        display: flex;
        height: 80px;
        padding-left: 1rem;
        width: 300px;
        justify-content: center;
        align-items: center;
        background-color: #4c6566;
        border-radius: 5px;
    }
    .email{
        width: 200px;
        height: 50px;
        border-radius: 5px;
        margin-right: 5px;
        background-color: #4c6566;
        border: 3px solid #4c6566;
    }
    .email::placeholder{
        color: darkgray;
    }
    .btn-email{
        width: 80px;
        height: 40px;
        border-radius: 5px;
        background-color: #104547;
        border: 4px solid #104547;
        cursor: pointer;
    }   
}