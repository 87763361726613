.container-logo{
    width: 50px;
    height: 50px;
}
.container{
    height: 230px;
    width: 270px;
    border-radius: 5px;
    background-color: #4c6566;
    padding-left: 1rem;
    color: white;
    cursor: pointer;
}
.container:hover{
    background-color: #549F93;
}
.Heading-discription{
    font-size: large;
}
@media only screen and (min-width: 250px){
    .container-logo{
        width: 50px;
        height: 50px;
    }
    .container{
        height: 180px;
        width: 210px;
        border-radius: 5px;
        background-color: #4c6566;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        color: white;
        cursor: pointer;
    }
    .container:hover{
        background-color: #549F93;
    }
    .Heading-discription{
        font-size: 16px;
    }
    .Container-details{
        font-size: 15px;
    }
}
@media only screen and (min-width: 768px){
    .container{
        height: 180px;
        width: 210px;
        border-radius: 5px;
        background-color: #4c6566;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        color: white;
        cursor: pointer;
    }
    .container:hover{
        background-color: #549F93;
    }
    .Heading-discription{
        font-size: 16px;
    }
    .Container-details{
        font-size: 15px;
    }
}
@media only screen and (min-width: 900px){
    .container-logo{
        width: 50px;
        height: 50px;
    }
    .container{
        height: 180px;
        width: 180px;
        border-radius: 5px;
        background-color: #4c6566;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        color: white;
        cursor: pointer;
    }
    .container:hover{
        background-color: #549F93;
    }
    .Heading-discription{
        font-size: 15px;
    }
    .Container-details{
        font-size: 14px;
    }
}
@media only screen and (min-width: 950px){
    .container{
        height: 215px;
        width: 210px;
        border-radius: 5px;
        background-color: #4c6566;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        color: white;
        cursor: pointer;
    }
    .container:hover{
        background-color: #549F93;
    }
    .Heading-discription{
        font-size: 18px;
    }
    .Container-details{
        font-size: 17px;
    }
}
@media only screen and (min-width: 1150px){
    .container-logo{
        width: 50px;
        height: 50px;
    }
    .container{
        height: 230px;
        width: 270px;
        border-radius: 5px;
        background-color: #4c6566;
        padding-left: 1rem;
        color: white;
        cursor: pointer;
    }
    .container:hover{
        background-color: #549F93;
    }
    .Heading-discription{
        font-size: large;
    }
}