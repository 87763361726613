.Program-heading{
    padding-left: 2rem;
    padding-top: 12vh;
    padding-bottom: 7vh;
    display: flex;
    gap:  3.5rem;
    align-items: center;
    background-color: #104547;
}
.program{
    margin-top: -17vh;
}
@media only screen and (min-width: 200px) {
    .Program-heading{
        width: 100vw;
        justify-content: center;
        display: flex;
        flex-direction: column;
        padding-top: 12vh;
        padding-bottom: 5vh;
        display: flex;
        gap:  2rem;
        margin-left: -1.5rem;
        align-items: center;
        background-color: #104547;
    }
    .program{
        margin-top: -17vh;
    }
    .stroke--text{
        height: 29px;
    }
}
@media only screen and (min-width: 768px){
    .Program-heading{
        width: 100vw;
        justify-content: center;
        padding-top: 12vh;
        padding-bottom: 5vh;
        display: flex;
        gap:  2.5rem;
        align-items: center;
        background-color: #104547;
    }
    .program{
        margin-top: -17vh;
    }
    .stroke--text{
        height: 29px;
    }
}
@media only screen and (min-width: 900px){
    .Program-heading{
        width: 100vw;
        justify-content: center;
        padding-top: 12vh;
        padding-bottom: 5vh;
        display: flex;
        gap:  2.5rem;
        align-items: center;
        background-color: #104547;
    }
    .program{
        margin-top: -17vh;
    }
    
}
@media only screen and (min-width: 1200px){
    .Program-heading{
        padding-left: 2rem;
        padding-top: 12vh;
        padding-bottom: 7vh;
        display: flex;
        gap:  3.5rem;
        align-items: center;
        background-color: #104547;
    }
    .program{
        margin-top: -17vh;
    }
}